import { getConfigPrice } from './getConfigPrice';
import { findMatchingVariant } from './findMatchingVariant';
import { TProductPrice, LowestPrice, SwatchOptions } from '@/types/product';
import { Nullable } from '@/components/AppIntegrations/types';
import { TParsedProductForContext } from '@/components/RootComponents/Product/types';

interface IGetPrices extends TProductPrice {
    lowestPriceData: LowestPrice;
}

export const getPrices = (
    currencyCode: Nullable<string>,
    optionCodes: SwatchOptions['optionCodes'],
    optionSelections: SwatchOptions['optionSelections'],
    productSwatches: TParsedProductForContext,
): IGetPrices => {
    const configPrice = getConfigPrice(productSwatches, optionCodes, optionSelections, currencyCode);

    const lowestPriceData = findMatchingVariant({
        optionCodes,
        optionSelections,
        variants: productSwatches?.variants || [],
    })?.product?.lowest_price_data;

    return { ...configPrice, lowestPriceData };
};
