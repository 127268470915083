'use client';

import React, { ReactElement, useContext } from 'react';
import Options from './options';
import { ProductContext } from '@/components/RootComponents/Product/context/productContext';
import { useBMAddToCart, useBoldMetricsObject } from '@/integrations/bold-metrics';
import { useBoldMetricsContext } from '@/integrations/bold-metrics/src/context/boldMetrics';

interface IProductOptionsProps {
    isFitAnalyticsEnabled: boolean;
    isModifyUrlParams: boolean;
    isSizeGuideEnabled: boolean;
}

const ProductOptions = ({
    isFitAnalyticsEnabled,
    isModifyUrlParams,
    isSizeGuideEnabled,
}: IProductOptionsProps): ReactElement => {
    const { boldMetricsCallToAction, isBoldMetricsEnabled } = useBoldMetricsContext();

    const {
        description,
        handleAddToCart,
        handleSelectionChange,
        hhProductType,
        id,
        isAddToCartDisabled,
        isInStock,
        isShakeAnimationActive,
        name,
        optionSelections,
        productOptions,
        productPrice,
        selectedColorSku,
        setSelectedSize,
        sizeChart,
        variants,
    } = useContext(ProductContext);

    const product = {
        description: description?.html,
        hh_product_type: hhProductType,
        id,
        name,
        price_range: productPrice,
        sizechart: sizeChart,
    };
    useBoldMetricsObject({ isBoldMetricsEnabled, product, productOptions, productSwatches: { variants } });
    useBMAddToCart({ boldMetricsCallToAction, handleAddToCart, isBoldMetricsEnabled, setSelectedSize });

    return (
        <Options
            isAddToCartDisabled={isAddToCartDisabled}
            isFitAnalyticsEnabled={isFitAnalyticsEnabled}
            isInStock={isInStock}
            isModifyUrlParams={isModifyUrlParams}
            isShakeAnimationActive={isShakeAnimationActive}
            isSizeGuideEnabled={isSizeGuideEnabled}
            onSelectionChange={handleSelectionChange}
            options={productOptions}
            selectedColorSku={selectedColorSku}
            selectedValues={optionSelections}
            setSelectedSize={setSelectedSize}
            sizeChart={sizeChart}
            hhProductType={hhProductType}
            productName={name}
        />
    );
};

export default ProductOptions;
