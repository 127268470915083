import React, { ReactElement, useMemo } from 'react';
import Swatch from './swatch';
import { IValueListProps } from '@/components/ProductOptions/types';
import classes from './swatchList.module.scss';

const SwatchList = ({ getItemKey, items, onSelectionChange, selectedValue }: IValueListProps): ReactElement => {
    const swatches = useMemo(
        () =>
            items
                .sort((a, b) => (a.hasDiscount === b.hasDiscount ? 0 : a.hasDiscount ? -1 : 1))
                .map((item) => {
                    const isSelected = item.label === selectedValue?.label;

                    return (
                        <Swatch
                            key={getItemKey(item)}
                            isSelected={isSelected}
                            item={item}
                            onClick={onSelectionChange}
                        />
                    );
                }),
        [getItemKey, selectedValue?.label, items, onSelectionChange],
    );

    return (
        <div className={classes.swatchList} data-mt-type="product-swatch-list">
            {swatches}
        </div>
    );
};

// SwatchList.propTypes = {
//     classes: shape({
//         root: string,
//     }),
//     getItemKey: func,
//     items: arrayOf(
//         shape({
//             file: string.isRequired,
//             hasDiscount: bool,
//             isDisabled: bool,
//             label: string.isRequired,
//             value_index: oneOfType([number, string]).isRequired,
//         }),
//     ),
//     onSelectionChange: func,
//     selectedValue: shape({
//         label: string,
//     }),
// };

export default SwatchList;
