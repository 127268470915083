'use client';

import React, { createContext, ReactNode } from 'react';
import useProduct from '../hooks/useProduct';
import { IProductContext, TParsedProductForContext } from '../types';
import { IBreadcrumbsCsrPdpContext } from '@/components/Breadcrumbs/modules/BreadcrumbsCsrPdp/types';

export const ProductContext = createContext<IProductContext>({} as IProductContext);

interface IProductProviderProps {
    breadCrumbsData: IBreadcrumbsCsrPdpContext;
    children: ReactNode;
    colorValue?: number;
    currencyCode: string;
    freeShippingEligibleThreshold: string | null;
    isProductTypeConfigurable: boolean;
    isSupportedProductType: boolean;
    product: TParsedProductForContext;
    sizeValue?: number;
}

export const ProductProvider = ({
    breadCrumbsData,
    children,
    colorValue,
    currencyCode,
    freeShippingEligibleThreshold,
    isProductTypeConfigurable,
    isSupportedProductType,
    product,
    sizeValue,
}: IProductProviderProps) => {
    const productContextValues = useProduct({
        colorValue,
        currencyCode,
        freeShippingEligibleThreshold,
        isProductTypeConfigurable,
        isSupportedProductType,
        product,
        sizeValue,
    });

    return (
        <ProductContext.Provider value={{ ...productContextValues, breadCrumbsData }}>
            {children}
        </ProductContext.Provider>
    );
};
