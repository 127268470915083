import { useCallback, useEffect, useMemo, useState } from 'react';
import { deriveOptionCodesFromProduct } from '@/components/RootComponents/Product/utils/deriveOptionCodesFromProduct';
import { deriveOptionSelectionsFromProduct } from '@/components/RootComponents/Product/utils/deriveOptionSelectionsFromProduct';
import { getConfigPrice } from '@/components/RootComponents/Product/utils/getConfigPrice';
import { getIsMissingOptions } from '@/components/RootComponents/Product/utils/getIsMissingOptions';
import { getIsOutOfStock } from '@/components/RootComponents/Product/utils/getIsOutOfStock';
import { getMediaGalleryEntries } from '@/components/RootComponents/Product/utils/getMediaGalleryEntries';
import { modifiedConfigOptions } from '@/components/RootComponents/Product/utils/modifiedConfigOptions';
import { findMatchingVariant } from '@/components/RootComponents/Product/utils/findMatchingVariant';
import { useFitAnalyticsProduct } from '@vaimo/fit-analytics';
import type { ProductSwatchOptions, SwatchOptions } from '@/types/product';
import type { IUseProductSwatchesProps, IUseProductSwatches } from '../types';
import { PRODUCT_IMAGE_URL_PREFIX } from '@/components/OptimizedImage/utils/prefixes';

export const useProductSwatches = ({
    colorValue: derivedColor,
    currencyCode,
    fitAnalyticsObject,
    handleShakeAnimation,
    isProductTypeConfigurable,
    product,
    setIsShakeAnimationActive,
    sizeValue: derivedSize,
}: IUseProductSwatchesProps): IUseProductSwatches => {
    const [currentOptions, setCurrentOptions] = useState<ProductSwatchOptions[]>([]);
    const [optionSelections, setOptionSelections] = useState<SwatchOptions['optionSelections']>(
        deriveOptionSelectionsFromProduct(product, derivedColor, derivedSize),
    );

    useEffect(() => {
        const nextOptionSelections = new Map(optionSelections);
        const colorOnlyOptions = deriveOptionSelectionsFromProduct(product, derivedColor);
        colorOnlyOptions.forEach((value, key) => {
            if (value) {
                nextOptionSelections.set(key, value);
            }
        });
        setOptionSelections(nextOptionSelections);
    }, [derivedColor]);

    const optionCodes = deriveOptionCodesFromProduct(product);
    const isMissingOptions = getIsMissingOptions(product, optionSelections);
    const productPrice = getConfigPrice(product, optionCodes, optionSelections, currencyCode);
    const productOptions = modifiedConfigOptions(product, optionSelections, optionCodes);
    const isOutOfStock = getIsOutOfStock({
        optionCodes,
        optionSelections,
        variants: product.variants,
    });
    const mediaGalleryEntries = getMediaGalleryEntries(product, optionCodes, optionSelections);

    const handleSelectionChange = useCallback(
        (optionId: string, selection: number) => {
            // We must create a new Map here so that React knows that the value
            // of optionSelections has changed.
            const nextOptionSelections = new Map(optionSelections);
            nextOptionSelections.set(optionId, selection);
            setOptionSelections(nextOptionSelections);

            if (!isMissingOptions) {
                setIsShakeAnimationActive(false);
            }
        },
        [isMissingOptions, optionSelections, setIsShakeAnimationActive],
    );

    // The map of ids to values (and their uids)
    // For example:
    // { "179" => [{ uid: "abc", value_index: 1 }, { uid: "def", value_index: 2 }]}
    const attributeIdToValuesMap = useMemo(() => {
        const map = new Map();
        // For simple items, this will be an empty map.
        const options = product.configurable_options || [];
        for (const { attribute_id, label, values } of options) {
            map.set(attribute_id, { label, values });
        }

        return map;
    }, [product?.configurable_options]);

    // An array of selected option uids. Useful for passing to mutations.
    // For example:
    // ["abc", "def"]
    const selectedOptionsArray = useMemo(() => {
        const selectedOptions: string[] = [];

        (optionSelections || []).forEach((value, key) => {
            const values = attributeIdToValuesMap.get(key).values;
            const selectedValue = values.find((item: any) => item.value_index === value);

            if (selectedValue) {
                const { label, uid } = selectedValue;
                setCurrentOptions([{ option_label: attributeIdToValuesMap.get(key).label, option_value: label }]);
                selectedOptions.push(uid);
            }
        });

        return selectedOptions;
    }, [attributeIdToValuesMap, optionSelections]);

    useEffect(() => {
        if (isOutOfStock) {
            handleShakeAnimation();
        }
    }, [isOutOfStock]);

    const wishlistItemOptions = {
        quantity: 1,
        selected_options: isProductTypeConfigurable ? selectedOptionsArray : undefined,
        sku: product.sku,
    };

    const wishlistButtonProps = {
        item: wishlistItemOptions,
        itemName: product?.name,
    };

    useFitAnalyticsProduct({
        currencyCode,
        matchingItem: findMatchingVariant({
            optionCodes,
            optionSelections,
            variants: product?.variants || [],
        }),
        product,
        productOptions,
        thumbnail: mediaGalleryEntries[0]?.file.includes(PRODUCT_IMAGE_URL_PREFIX)
            ? mediaGalleryEntries[0]?.file
            : `/${PRODUCT_IMAGE_URL_PREFIX}${mediaGalleryEntries[0]?.file}`,
        variants: product?.variants || [],
        ...fitAnalyticsObject,
    });

    return {
        currentOptions,
        handleSelectionChange,
        isMissingOptions,
        isOutOfStock,
        mediaGalleryEntries,
        optionCodes,
        optionSelections,
        productOptions,
        productPrice,
        wishlistButtonProps,
    };
};
