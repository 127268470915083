import { useCallback } from 'react';

export const useSwatch = ({ onClick, valueIndex }) => {
    const handleClick = useCallback(() => {
        onClick(valueIndex);
    }, [valueIndex, onClick]);

    return {
        handleClick,
    };
};
