import { isProductConfigurableAndHasOptions } from './isProductConfigurableAndHasOptions';
import type { SwatchOptions } from '@/types/product';
import { TParsedProductForContext } from '@/components/RootComponents/Product/types';
import { COLOR_CODE, SIZE_CODE } from '@/components/RootComponents/Product/utils/const';

export const deriveOptionSelectionsFromProduct = (
    product: TParsedProductForContext,
    color: number | undefined,
    size?: number | null,
): SwatchOptions['optionSelections'] =>
    isProductConfigurableAndHasOptions(product)
        ? new Map(
              product?.configurable_options.map(({ attribute_code, attribute_id: id, values }) => {
                  if (attribute_code === SIZE_CODE) {
                      // pre-select size from url param
                      if (size) {
                          return [id, +size];
                      }

                      // pre-select size if only one is available
                      if (values.length === 1) {
                          const onlyAvailableSize = values[0];

                          return [id, onlyAvailableSize?.value_index];
                      }
                  }

                  if (attribute_code === COLOR_CODE) {
                      const filterOptions = values.filter((value) =>
                          product.variants.some((variant) =>
                              variant.attributes.some((attribute) => attribute.value_index === value.value_index),
                          ),
                      );

                      // pre-select color from url param
                      if (color && filterOptions.some((option) => option.value_index === +color)) {
                          return [id, +color];
                      }

                      // pre-select the first color or single element in the array
                      return [id, filterOptions[0]?.value_index];
                  }

                  return [id, undefined];
              }),
          )
        : new Map();
