import { useEffect, useMemo, useState } from 'react';
import { useQuery } from '@apollo/client';
import { GET_CMS_BLOCKS } from '@/components/CmsBlock/cmsBlock.gql.js';
import { deriveErrorMessage } from '@/lib/Apollo/utils/deriveErrorMessage';
import { transformConsecutiveCharsToNumber } from '@/utils/string';
import type { IProductSizeGuideProps } from '../types';
import type { ISizeChartDataResponse, IUseProductSizeGuide } from './types';
import { CmsBlocks } from '@/types/cms';
import { getProductSizeGuide } from './productSizeGuide.gql';

const CMS_SIZE_CHART_IDENTIFIER = 'size-chart-default';

export const useProductSizeGuide = ({
    availableSizesOptions,
    selectedColorSku,
}: IProductSizeGuideProps): IUseProductSizeGuide => {
    const [isSizeGuideModalOpen, setIsSizeGuideModalOpen] = useState<boolean>(false);
    const [howToMeasureSizeContent, setHowToMeasureSizeContent] = useState<string | null>(null);

    const { data: howToMeasureSizeData, loading: isSizeInstructionsLoading } = useQuery<CmsBlocks>(GET_CMS_BLOCKS, {
        fetchPolicy: 'cache-and-network',
        nextFetchPolicy: 'cache-first',
        skip: !isSizeGuideModalOpen || Boolean(howToMeasureSizeContent),
        variables: { identifiers: CMS_SIZE_CHART_IDENTIFIER },
    });

    const availableSizes = useMemo(
        () =>
            availableSizesOptions
                .map(
                    ({ default_label }) =>
                        `${selectedColorSku}-${transformConsecutiveCharsToNumber(default_label, 'X')}`,
                )
                .filter(Boolean) || [],
        [availableSizesOptions, selectedColorSku],
    );

    const {
        data: sizeChartData,
        error: sizeChartError,
        loading: isSizeChartLoading,
    } = useQuery<ISizeChartDataResponse>(getProductSizeGuide, {
        fetchPolicy: 'cache-and-network',
        nextFetchPolicy: 'cache-first',
        skip: !isSizeGuideModalOpen || !availableSizes.length,
        variables: {
            skus: availableSizes,
        },
    });

    useEffect(() => {
        if (isSizeInstructionsLoading || !howToMeasureSizeData) return;

        setHowToMeasureSizeContent(howToMeasureSizeData.cmsBlocks?.items[0]?.content || null);
    }, [howToMeasureSizeData, isSizeInstructionsLoading]);

    const derivedErrorMessage = useMemo(() => deriveErrorMessage([sizeChartError]), [sizeChartError]);

    return {
        derivedErrorMessage,
        howToMeasureSizeContent,
        isSizeChartLoading,
        isSizeGuideModalOpen,
        isSizeInstructionsLoading,
        setIsSizeGuideModalOpen,
        sizeChartData,
    };
};
