import { findMatchingVariant } from './findMatchingVariant';
import type { ConfigurableVariant, SwatchOptions } from '@/types/product';

const OUT_OF_STOCK_CODE = 'OUT_OF_STOCK';

interface IGetIsOutOfStock {
    optionCodes: SwatchOptions['optionCodes'];
    optionSelections: SwatchOptions['optionSelections'];
    variants?: ConfigurableVariant[];
}

export const getIsOutOfStock = ({ optionCodes, optionSelections, variants }: IGetIsOutOfStock): boolean => {
    if (!optionCodes || !optionSelections || !variants) return true;

    const item = findMatchingVariant({
        optionCodes,
        optionSelections,
        variants,
    });

    return !item?.product || item.product.stock_status === OUT_OF_STOCK_CODE;
};
