// Makes the transformation like 'XXXL' -> '3XL', but 'XL' -> 'XL'
export const transformConsecutiveCharsToNumber = (str: string, char: string) => {
    const regEx = new RegExp(`${char}{2,}`, 'g');

    return str.replace(regEx, (match) => `${match.length}${char}`);
};

// Makes the transformation like '3XL' -> 'XXXL', but 'XL' -> 'XL'
export const transformNumberToConsecutiveChars = (str: string, char: string) => {
    const regEx = new RegExp(`\\d${char}`, 'g');

    return str.replace(regEx, (match) => char.repeat(Number(match[0])));
};
