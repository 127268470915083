import { useCallback } from 'react';

const COLOR_CODE = 'color';

// helper for mapping data based on pre-selected options
const findSelectOptionLabel = (options, key, value) => {
    if (!options.length)
        return {
            index: null,
            label: null,
            option: null,
        };

    const selectedOption = options.find((option) => option.attribute_id === key);
    const selectedLabel = value ? selectedOption.values.find((item) => item.value_index === value)?.label : null;
    const selectedIndex = value ? selectedOption.values.findIndex((item) => item.value_index === value) : null;

    return {
        index: selectedIndex,
        label: selectedLabel,
        option: selectedOption.label,
    };
};

const getAttributeCodeById = (id, options) => {
    if (!options.length) return;

    const { attribute_code } = options.find((item) => item.attribute_id === id);

    return attribute_code;
};

export const useOptions = ({ isModifyUrlParams, onSelectionChange, options, selectedValues }) => {
    const handleSelectionChange = useCallback(
        (optionId, selection) => {
            if (!onSelectionChange) {
                return;
            }
            onSelectionChange(optionId, selection);

            let selectedParams = new URLSearchParams(globalThis.location.search);

            // Change the url using only color parameter
            if (getAttributeCodeById(optionId, options) === COLOR_CODE) {
                selectedParams.set(getAttributeCodeById(optionId, options), selection);
            } else {
                selectedParams.delete(getAttributeCodeById(optionId, options));
            }

            if (isModifyUrlParams) {
                globalThis.history.replaceState(history.state, '', `?${selectedParams.toString()}`);
            }
        },
        [onSelectionChange],
    );

    const selectedValueMap = new Map();
    for (const [key, value] of selectedValues) {
        const { index, label, option } = findSelectOptionLabel(options, key, value);
        selectedValueMap.set(option, { index, label });
    }

    return {
        handleSelectionChange,
        selectedValueMap,
    };
};
