import React, { ReactElement } from 'react';

import LoadingIndicator from '@/components/LoadingIndicator';
import RichContent from '@/components/RichContent';
import type { IHowToMeasureSizeProps } from '../types';

import classes from './howToMeasureSize.module.scss';

const HowToMeasureSize = ({
    howToMeasureSizeContent,
    isSizeInstructionsLoading,
}: IHowToMeasureSizeProps): ReactElement | null => {
    if (isSizeInstructionsLoading) {
        return <LoadingIndicator />;
    }

    if (!howToMeasureSizeContent) {
        return null;
    }

    return <RichContent html={howToMeasureSizeContent} classes={{ root: classes.root }} />;
};

export default HowToMeasureSize;
