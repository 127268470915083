import { useCallback, useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';
import { useCartContext } from '@/lib/context';
import { useAppContext } from '@/lib/context';

import { appendOptionsToPayload } from '../utils/appendOptionsToPayload';
import { ADD_TO_CART_ACTION_STATUS } from '@/talons/CurrentStatus/useCurrentStatus';

import type { IProduct } from '@/integrations/facebook-conversion-api/src/types';
import type { IAddToCartItem, IUseAddProductToCart, IUseAddProductToCartProps } from '../types';
import {
    addConfigurableProductToCartMutation,
    addSimpleProductToCartMutation,
} from '@/components/RootComponents/Product/api/productFullDetail.gql';
import { PRODUCT_NAME_REF } from '@/components/RootComponents/Product/utils/refId';

const SIMPLE_PRODUCT = 'SimpleProduct';
const CONFIGURABLE_PRODUCT = 'ConfigurableProduct';

export const useAddProductToCart = ({
    currentOptions,
    handleShakeAnimation,
    isMissingOptions,
    isSupportedProductType,
    mediaGalleryEntries,
    optionCodes,
    optionSelections,
    product,
    productPrice,
}: IUseAddProductToCartProps): IUseAddProductToCart => {
    const [facebookProductInCart, setFacebookProductInCart] = useState<IProduct | null>(null);

    const productType = product.__typename;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const isProductTypeSimple = productType === SIMPLE_PRODUCT;
    const isProductTypeConfigurable = productType === CONFIGURABLE_PRODUCT;

    const [{ cartId, uniqueConfigurableSkusInCart }] = useCartContext();
    const [
        ,
        {
            actions: { setAddToCartActionStatus, setAddToCartSuccessData },
        },
    ] = useAppContext();

    const [addConfigurableProductToCart, { error: errorAddingConfigurableProduct, loading: isAddConfigurableLoading }] =
        useMutation(addConfigurableProductToCartMutation, { context: { skipErrorToasts: true } });

    const [addSimpleProductToCart, { error: errorAddingSimpleProduct, loading: isAddSimpleLoading }] = useMutation(
        addSimpleProductToCartMutation,
        { context: { skipErrorToasts: true } },
    );

    useEffect(() => {
        return () => setAddToCartActionStatus(ADD_TO_CART_ACTION_STATUS.loading);
    }, [setAddToCartActionStatus]);

    useEffect(() => {
        if (!uniqueConfigurableSkusInCart) {
            return;
        }

        if (uniqueConfigurableSkusInCart.has(product.sku)) {
            setAddToCartActionStatus(ADD_TO_CART_ACTION_STATUS.more);

            return;
        }

        setAddToCartActionStatus(ADD_TO_CART_ACTION_STATUS.default);
    }, [product.sku, setAddToCartActionStatus, uniqueConfigurableSkusInCart]);

    const changeMessage = useCallback((messageType: string) => {
        const timer = setTimeout(() => {
            setAddToCartActionStatus(messageType);
        }, 1000);

        return () => clearTimeout(timer);
    }, []);

    const executeScroll = () => {
        globalThis.document.getElementById(PRODUCT_NAME_REF)?.scrollIntoView();
    };

    const handleAddToCart = useCallback(async () => {
        if (isMissingOptions) {
            return handleShakeAnimation();
        }

        const quantity = 1;
        const payload: IAddToCartItem = {
            item: product,
            quantity,
        };

        if (isProductTypeConfigurable) {
            // @ts-expect-error JS format error
            appendOptionsToPayload(payload, optionSelections, optionCodes);
        }

        const successDialogData = {
            image_props: mediaGalleryEntries[0],
            name: product.name,
            price: productPrice,
            qty: quantity,
            selected_options: currentOptions,
        };

        if (isSupportedProductType) {
            setAddToCartActionStatus(ADD_TO_CART_ACTION_STATUS.ongoing);

            const variables = {
                cartId,
                parentSku: payload.parentSku,
                quantity: payload.quantity,
                sku: payload.item.sku,
            };
            // Use the proper mutation for the type.
            if (isProductTypeSimple) {
                try {
                    await addSimpleProductToCart({
                        variables,
                    });
                    setAddToCartActionStatus(ADD_TO_CART_ACTION_STATUS.success);
                    setAddToCartSuccessData(successDialogData);
                } catch {
                    executeScroll();
                    setAddToCartActionStatus(ADD_TO_CART_ACTION_STATUS.error);
                    changeMessage(ADD_TO_CART_ACTION_STATUS.default);

                    return;
                }
            } else if (isProductTypeConfigurable) {
                try {
                    await addConfigurableProductToCart({
                        variables,
                    });
                    setFacebookProductInCart({
                        currency: payload.item?.price_range?.minimum_price?.final_price?.currency,
                        name: payload.item?.name,
                        quantity: variables.quantity,
                        sku: variables.sku,
                        value: payload.item?.price_range?.minimum_price?.final_price?.value * variables.quantity,
                    });
                    setAddToCartActionStatus(ADD_TO_CART_ACTION_STATUS.success);
                    setAddToCartSuccessData(successDialogData);
                } catch {
                    executeScroll();
                    setAddToCartActionStatus(ADD_TO_CART_ACTION_STATUS.error);
                    changeMessage(ADD_TO_CART_ACTION_STATUS.default);

                    return;
                }
            }
        } else {
            executeScroll();
            setAddToCartActionStatus(ADD_TO_CART_ACTION_STATUS.error);
            console.error('Unsupported product type. Cannot add to cart.');
        }

        changeMessage(ADD_TO_CART_ACTION_STATUS.more);
    }, [
        currentOptions,
        addConfigurableProductToCart,
        addSimpleProductToCart,
        cartId,
        isMissingOptions,
        isProductTypeConfigurable,
        isProductTypeSimple,
        optionCodes,
        optionSelections,
        product,
    ]);

    const errors = [errorAddingSimpleProduct?.message, errorAddingConfigurableProduct?.message].join('');

    return {
        errors,
        facebookProductInCart,
        handleAddToCart,
        isAddConfigurableLoading,
        isAddSimpleLoading,
    };
};
