'use client';

import React, { ReactElement } from 'react';
import Option from './option';
import { useOptions } from './hooks/useOptions';
import type { ConfigurableProductOptions, SwatchOptions } from '@/types/product';

interface IOptionsProps {
    hhProductType?: string | null;
    isAddToCartDisabled: boolean;
    isFitAnalyticsEnabled?: boolean;
    isInStock: boolean;
    isModifyUrlParams?: boolean;
    isShakeAnimationActive: boolean;
    isSizeGuideEnabled?: boolean;
    onSelectionChange: (optionId: string, selection: number) => void;
    options: ConfigurableProductOptions[];
    productName?: string;
    selectedColorSku?: string;
    selectedValues: SwatchOptions['optionSelections'];
    setSelectedSize?: (option: string) => void;
    sizeChart?: string | null;
}

const Options = ({
    hhProductType,
    isAddToCartDisabled,
    isFitAnalyticsEnabled = false,
    isInStock,
    isModifyUrlParams = false,
    isShakeAnimationActive,
    isSizeGuideEnabled = false,
    onSelectionChange,
    options,
    productName,
    selectedColorSku,
    selectedValues,
    setSelectedSize,
    sizeChart,
}: IOptionsProps): ReactElement => {
    const { handleSelectionChange, selectedValueMap } = useOptions({
        isModifyUrlParams,
        onSelectionChange,
        options,
        selectedValues,
    });

    return (
        <>
            {options.map((option) => (
                // @ts-expect-error force TS
                <Option
                    {...option}
                    key={option.attribute_id}
                    isAddToCartDisabled={isAddToCartDisabled}
                    isFitAnalyticsEnabled={isFitAnalyticsEnabled}
                    isInStock={isInStock}
                    isShakeAnimationActive={isShakeAnimationActive}
                    isSizeGuideEnabled={isSizeGuideEnabled}
                    onSelectionChange={handleSelectionChange}
                    selectedColorSku={selectedColorSku}
                    selectedIndex={selectedValueMap.get(option.label)?.index}
                    selectedValue={selectedValueMap.get(option.label)?.label}
                    setSelectedSize={setSelectedSize}
                    sizeChart={sizeChart}
                    hhProductType={hhProductType}
                    productName={productName}
                />
            ))}
        </>
    );
};

export default Options;
