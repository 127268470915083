import { useCallback, useMemo, useState } from 'react';

/**
 * Talon for Option.
 *
 * @param {number} props.attribute_id the id of the option
 * @param {function} props.onSelectionChange callback handler for when the option is clicked
 * @param {string} props.selectedValue the label of the selected option
 * @param {array} props.values an array containing possible values
 */
export const useOption = ({ attribute_id, onSelectionChange, selectedValue, values }: any) => {
    const [selection, setSelection] = useState(null);
    const initialSelection = useMemo(() => {
        let initialSelection: Record<string, any> = {};
        const searchValue = selection || selectedValue;
        if (searchValue) {
            initialSelection = values.find((value: any) => value.default_label === searchValue) || {};
        }

        return initialSelection;
    }, [selectedValue, selection, values]);

    const valuesMap = useMemo<Map<string, any>>(() => {
        return new Map(values.map((value: any) => [value.value_index, value.store_label]));
    }, [values]);

    const selectedValueDescription = selection || initialSelection.default_label || 'None';

    const handleSelectionChange = useCallback(
        (selection: any) => {
            setSelection(valuesMap.get(selection));

            if (onSelectionChange) {
                onSelectionChange(attribute_id, selection);
            }
        },
        [attribute_id, onSelectionChange, valuesMap],
    );

    return {
        handleSelectionChange,
        initialSelection,
        selectedValueDescription,
    };
};
