import React, { ReactElement } from 'react';
import { ButtonSelector } from '@vaimo-int/aem-pwa-common-components';
import type { IValueProps } from '@/components/ProductOptions/types';
import defaultClasses from '../TileList/tileList.module.scss';

const Tile = ({ isSelected, item: { isDisabled, label, value_index }, onClick }: IValueProps): ReactElement => {
    const handleClick = () => {
        onClick(value_index);
    };

    return (
        <ButtonSelector
            onClick={handleClick}
            label={label}
            type="button"
            disabled={isDisabled}
            active={isSelected}
            className={defaultClasses.tile}
        />
    );
};

export default Tile;
