import { gql } from '@apollo/client';
import { CartTriggerFragment } from '@/components/Header/api/cartTriggerFragments.gql';
import { MiniCartFragment } from '@/components/MiniCart/api/miniCartFragments.gql';

export const getWishlistConfigQuery = gql`
    query GetWishlistConfigForProductCE {
        storeConfig {
            id
            magento_wishlist_general_is_enabled
        }
    }
`;
export const addProductToCartMutation = gql`
    mutation AddProductToCart($cartId: String!, $product: CartItemInput!) {
        addProductsToCart(cartId: $cartId, cartItems: [$product]) {
            cart {
                id
                ...CartTriggerFragment
                ...MiniCartFragment
            }
        }
    }
    ${CartTriggerFragment}
    ${MiniCartFragment}
`;

export const addConfigurableProductToCartMutation = gql`
    mutation addConfigurableProductToCart($cartId: String!, $quantity: Float!, $sku: String!, $parentSku: String!) {
        addConfigurableProductsToCart(
            input: {
                cart_id: $cartId
                cart_items: [{ data: { quantity: $quantity, sku: $sku }, parent_sku: $parentSku }]
            }
        ) @connection(key: "addConfigurableProductsToCart") {
            cart {
                id
                # Update the cart trigger when adding an item.
                ...CartTriggerFragment
                # Update the mini cart when adding an item.
                ...MiniCartFragment
            }
        }
    }
    ${CartTriggerFragment}
    ${MiniCartFragment}
`;

export const addSimpleProductToCartMutation = gql`
    mutation addSimpleProductToCart($cartId: String!, $quantity: Float!, $sku: String!) {
        addSimpleProductsToCart(
            input: { cart_id: $cartId, cart_items: [{ data: { quantity: $quantity, sku: $sku } }] }
        ) @connection(key: "addSimpleProductsToCart") {
            cart {
                id
                # Update the cart trigger when adding an item.
                ...CartTriggerFragment
                # Update the mini cart when adding an item.
                ...MiniCartFragment
            }
        }
    }
    ${CartTriggerFragment}
    ${MiniCartFragment}
`;
