import { useMemo, useState } from 'react';
import { transformNumberToConsecutiveChars } from '@/utils/string';
import { UNITS } from './types';
import type {
    SizeAttribute,
    IUseProductSizeGuideTable,
    IUseProductSizeGuideTableProps,
    UnitsKeys,
    SizeTableRow,
} from './types';

const EMPTY_VALUE = '-';

const transformRawSizeChartData = (sizeChartValues: SizeAttribute[], unitDataKey: string): SizeTableRow[] => {
    const rows: SizeTableRow[] = [];

    for (const key in sizeChartValues[0]) {
        const row = { attributeCode: key } as SizeTableRow;

        // @ts-expect-error force TS
        const values = sizeChartValues.map((size) => size[key]?.[unitDataKey] || EMPTY_VALUE);

        if (values.some((value: string) => value !== EMPTY_VALUE)) {
            row[key] = values;

            rows.push(row);
        }
    }

    return rows;
};

export const useProductSizeGuideTable = ({
    sizeChartData,
}: IUseProductSizeGuideTableProps): IUseProductSizeGuideTable => {
    const [selectedUnit, setSelectedUnit] = useState<UnitsKeys>('CM');

    const sizeChartMap = useMemo(() => {
        if (!sizeChartData) {
            return new Map();
        }

        return new Map(
            sizeChartData.getSizeChartAttributes?.map((sizeData) => {
                const { __typename, sku, ...sizeAttributes } = sizeData;

                const sizeCode = transformNumberToConsecutiveChars(sku.split('-')?.[1], 'X');

                return [sizeCode, sizeAttributes];
            }),
        );
    }, [sizeChartData]);

    const tableHeaderRow: string[] = useMemo(
        () => (Boolean(sizeChartMap) && [...sizeChartMap.keys()].map((sizeKey) => sizeKey)) || [],
        [sizeChartMap],
    );

    const tableDataRows = useMemo(
        () =>
            (Boolean(sizeChartMap) &&
                transformRawSizeChartData([...sizeChartMap.values()], UNITS[selectedUnit].dataKey)) ||
            [],
        [selectedUnit, sizeChartMap],
    );

    return {
        selectedUnit,
        setSelectedUnit,
        tableDataRows,
        tableHeaderRow,
    };
};
