import React, { ReactElement } from 'react';
import DiscountBadgeIcon from './assets/discountBadge';
import clsx from 'clsx';
import defaultClasses from './discountBadge.module.scss';

interface IDiscountBadgeProps {
    isSmall?: boolean;
}

const DiscountBadge = ({ isSmall = false }: IDiscountBadgeProps): ReactElement => {
    const classes = clsx(defaultClasses.root, {
        [defaultClasses.smallBadge]: isSmall,
    });

    return (
        <div className={classes}>
            <DiscountBadgeIcon />
        </div>
    );
};

export default DiscountBadge;
