import { isProductConfigurableAndHasOptions } from './isProductConfigurableAndHasOptions';
import type { SwatchOptions } from '@/types/product';
import { TParsedProductForContext } from '@/components/RootComponents/Product/types';

export const getIsMissingOptions = (
    product: TParsedProductForContext,
    optionSelections: SwatchOptions['optionSelections'],
): boolean => {
    // Non-configurable products can't be missing options.
    if (!isProductConfigurableAndHasOptions(product) || !optionSelections) {
        return false;
    }

    // Configurable products are missing options if we have fewer
    // option selections than the product has options.
    const { configurable_options = [] } = product || {};
    const numProductOptions = configurable_options.length;
    const numProductSelections = [...optionSelections.values()].filter(Boolean).length;

    return numProductSelections < numProductOptions;
};
