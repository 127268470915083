import React, { ReactElement, useMemo } from 'react';
import Tile from '../Tile';
import type { IValueListProps } from '@/components/ProductOptions/types';

import defaultClasses from './tileList.module.scss';

const TileList = (props: IValueListProps): ReactElement => {
    const { getItemKey, items, onSelectionChange, selectedValue } = props;

    const tiles = useMemo(
        () =>
            items.map((item) => {
                const isSelected = item.label === selectedValue.label;

                return <Tile key={getItemKey(item)} isSelected={isSelected} item={item} onClick={onSelectionChange} />;
            }),
        [getItemKey, selectedValue.label, items, onSelectionChange],
    );

    return (
        <div className={defaultClasses.tileList} data-mt-type="product-tile-list" data-bm-type="product-tile-list">
            {tiles}
        </div>
    );
};

export default TileList;
