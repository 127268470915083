import React, { ReactElement } from 'react';
import { useProductSizeGuide } from './hooks/useProductSizeGuide';
import { Button2 } from '@vaimo-int/aem-pwa-common-components';
import Modal from '@/components/Modal';
import HowToMeasureSize from './HowToMeasureSize/howToMeasureSize';
import SizeGuideTable from './SizeGuideTable/sizeGuideTable';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import type { IProductSizeGuideProps } from './types';
import { useTranslations } from 'next-intl';

import classes from './productSizeGuide.module.scss';
import 'react-tabs/style/react-tabs.css';

const ProductSizeGuide = ({ availableSizesOptions, selectedColorSku }: IProductSizeGuideProps): ReactElement => {
    const {
        derivedErrorMessage,
        howToMeasureSizeContent,
        isSizeChartLoading,
        isSizeGuideModalOpen,
        isSizeInstructionsLoading,
        setIsSizeGuideModalOpen,
        sizeChartData,
    } = useProductSizeGuide({ availableSizesOptions, selectedColorSku });
    const t = useTranslations('productSizeGuide');

    const sizeGuideTitle = t('sizeGuide');

    return (
        <>
            <Button2
                aria-label={sizeGuideTitle}
                className={classes.button}
                onClick={() => setIsSizeGuideModalOpen(true)}
                role="button"
                tab-index="0"
                type={'button'}
                variant={'trigger'}
            >
                {sizeGuideTitle}
            </Button2>
            <Modal
                active={isSizeGuideModalOpen}
                className={classes.modalDialog}
                close={() => setIsSizeGuideModalOpen(false)}
                shouldCloseOnMaskClick
                title={sizeGuideTitle}
            >
                <Tabs>
                    <TabList className={classes.tabList}>
                        <Tab className={classes.tab} selectedClassName={classes.tabSelected}>
                            {t('sizeChart')}
                        </Tab>
                        <Tab className={classes.tab} selectedClassName={classes.tabSelected}>
                            {t('howToMeasure')}
                        </Tab>
                    </TabList>
                    <TabPanel>
                        <SizeGuideTable
                            isSizeChartLoading={isSizeChartLoading}
                            sizeChartData={sizeChartData}
                            sizeChartError={derivedErrorMessage}
                        />
                    </TabPanel>
                    <TabPanel>
                        <HowToMeasureSize
                            howToMeasureSizeContent={howToMeasureSizeContent}
                            isSizeInstructionsLoading={isSizeInstructionsLoading}
                        />
                    </TabPanel>
                </Tabs>
            </Modal>
        </>
    );
};

export default React.memo(ProductSizeGuide);
