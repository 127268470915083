import React, { ReactElement } from 'react';
import { useSwatch } from './hooks/useSwatch';
import Image from '@/components/OptimizedImage';
import DiscountBadge from '@/components/DiscountBadge';
import clsx from 'clsx';
import { IValueProps } from '@/components/ProductOptions/types';
import classes from './swatch.module.scss';

const SWATCH_SIZE = 50;
const BUTTON_SIZE = 50;

const Swatch = (props: IValueProps): ReactElement | null => {
    const {
        isSelected,
        item: { file, hasDiscount, isHidden, label, value_index },
        onClick,
    } = props;

    const { handleClick } = useSwatch({
        onClick,
        valueIndex: value_index,
    });

    const className = clsx(classes.swatch, {
        [classes.swatchDisabled]: isHidden,
        [classes.swatchSelected]: isSelected,
    });

    if (isHidden) {
        return null;
    }

    return (
        <div className={classes.swatchWrapper} style={{ height: BUTTON_SIZE, width: BUTTON_SIZE }}>
            <button
                className={className}
                onClick={handleClick}
                title={label}
                type="button"
                style={{ height: BUTTON_SIZE, width: BUTTON_SIZE }}
            >
                <Image isImgTag alt={label} width={SWATCH_SIZE} height={SWATCH_SIZE} src={file} />
                {hasDiscount && <DiscountBadge />}
            </button>
        </div>
    );
};

export default Swatch;
