import React from 'react';
import { IconProps } from '@/icons/types';

const DiscountBadge = ({ height = 20, width = 20, ...props }: IconProps) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        id="Discount_Badge"
        viewBox="0 0 20 20"
        width={width}
        height={height}
        {...props}
        fill="none"
    >
        <path fill="#fff" d="m7.256 13.923 6.667-6.667-1.178-1.178-6.667 6.667 1.178 1.178Z" />
        <path
            fill="#fff"
            d="M7.5 10C8.878 10 10 8.878 10 7.5S8.878 5 7.5 5A2.503 2.503 0 0 0 5 7.5C5 8.878 6.122 10 7.5 10Zm0-3.333a.834.834 0 1 1 0 1.667.834.834 0 0 1 0-1.667ZM12.5 10a2.503 2.503 0 0 0-2.5 2.5c0 1.378 1.122 2.5 2.5 2.5s2.5-1.122 2.5-2.5-1.122-2.5-2.5-2.5Zm0 3.333a.834.834 0 1 1 .002-1.668.834.834 0 0 1-.002 1.668Z"
        />
    </svg>
);

export default DiscountBadge;
