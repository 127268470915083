import { gql } from '@apollo/client';

export const getProductSizeGuide = gql`
    query GetProductSizeGuide($skus: [String!]) {
        getSizeChartAttributes(skus: $skus) {
            sku
            size_chest {
                value_in_cm
                value_in_inch
            }
            size_waist {
                value_in_cm
                value_in_inch
            }
            size_centre_back {
                value_in_cm
                value_in_inch
            }
            size_sleeve_from_centre {
                value_in_cm
                value_in_inch
            }
            size_sleeve_from_high {
                value_in_cm
                value_in_inch
            }
            size_waist_relaxed {
                value_in_cm
                value_in_inch
            }
            size_waist_stretched {
                value_in_cm
                value_in_inch
            }
            size_inseam {
                value_in_cm
                value_in_inch
            }
            size_chest_middle {
                value_in_cm
                value_in_inch
            }
            size_waist_middle {
                value_in_cm
                value_in_inch
            }
            size_centre_back_middle {
                value_in_cm
                value_in_inch
            }
            size_sleeve_from_centre_middle {
                value_in_cm
                value_in_inch
            }
            size_sleeve_from_high_middle {
                value_in_cm
                value_in_inch
            }
            size_chest_inner {
                value_in_cm
                value_in_inch
            }
            size_waist_inner {
                value_in_cm
                value_in_inch
            }
            size_centre_back_inner {
                value_in_cm
                value_in_inch
            }
            size_sleeve_from_centre_inner {
                value_in_cm
                value_in_inch
            }
            size_sleeve_from_high_inner {
                value_in_cm
                value_in_inch
            }
            size_chest_inner2 {
                value_in_cm
                value_in_inch
            }
            size_waist_inner2 {
                value_in_cm
                value_in_inch
            }
            size_centre_back_inner2 {
                value_in_cm
                value_in_inch
            }
            size_sleeve_from_centre_inner2 {
                value_in_cm
                value_in_inch
            }
            size_sleeve_from_high_inner2 {
                value_in_cm
                value_in_inch
            }
        }
    }
`;
