import React, { ReactElement } from 'react';
import { useProductSizeGuideTable } from '../hooks/useProductSizeGuideTable';
import { Typography, Button2 } from '@vaimo-int/aem-pwa-common-components';
import LoadingIndicator from '@/components/LoadingIndicator';
import Notification from '@/components/Notification';
import type { ISizeGuideTableProps } from '../types';
import clsx from 'clsx';
import { useTranslations } from 'next-intl';
import { UNITS, UnitsKeys } from '@/components/RootComponents/Product/modules/ProductSizeGuide/hooks/types';
import classes from './sizeGuideTable.module.scss';

const SizeGuideTable = ({ isSizeChartLoading, sizeChartData, sizeChartError }: ISizeGuideTableProps): ReactElement => {
    const { selectedUnit, setSelectedUnit, tableDataRows, tableHeaderRow } = useProductSizeGuideTable({
        sizeChartData,
    });
    const t = useTranslations('productSizeGuide');

    if (isSizeChartLoading) {
        return <LoadingIndicator />;
    }

    if (!tableDataRows?.length || sizeChartError) {
        return <Notification>{t('informationNotAvailable')}</Notification>;
    }

    const tableHeader = tableHeaderRow.map((sizeKey: string) => (
        <th key={sizeKey} className={classes.cellHead}>
            {sizeKey}
        </th>
    ));
    const tableRows = tableDataRows.map((row) => (
        <tr key={row.attributeCode} className={classes.rowBody}>
            <td key={row.attributeCode} className={classes.stickyColumnBody}>
                {t(row.attributeCode)}
            </td>
            {...row[row.attributeCode].map((attributeValue: string, idx: number) => (
                <td key={`${row.attributeCode}-${tableHeaderRow[idx]}`} className={classes.cellBody}>
                    {attributeValue}
                </td>
            ))}
        </tr>
    ));

    return (
        <div className={classes.content}>
            <ul className={classes.unitsSelectorWrapper}>
                {Object.keys(UNITS).map((unitKey: string) => (
                    <li
                        key={unitKey}
                        className={classes.unitsSelector}
                        onClick={() => setSelectedUnit(unitKey as UnitsKeys)}
                    >
                        <Button2
                            className={clsx({
                                [classes.selectedUnit]: selectedUnit === unitKey,
                            })}
                            variant={'trigger'}
                        >
                            {t(unitKey)}
                        </Button2>
                    </li>
                ))}
            </ul>
            <Typography.Body style={'basic'}>
                {t('measurementDisclaimer')}
                <br />
                {t('measurementsIn')} {t(UNITS[selectedUnit].value)}
                <span className={classes.measurementUnit}>{t(UNITS[selectedUnit].value)}</span>
            </Typography.Body>
            <div className={classes.tableWrapper}>
                <table className={classes.table}>
                    <thead className={classes.rowHead}>
                        <tr>
                            <th key="emptyCell" className={classes.stickyColumnHeader}></th>
                            {...tableHeader}
                        </tr>
                    </thead>
                    <tbody>{tableRows}</tbody>
                </table>
            </div>
        </div>
    );
};

export default SizeGuideTable;
