import { findMatchingVariant } from './findMatchingVariant';
import { isProductConfigurableAndHasOptions } from './isProductConfigurableAndHasOptions';
import type { TProductPrice, SwatchOptions } from '@/types/product';
import { TParsedProductForContext } from '@/components/RootComponents/Product/types';

export const getConfigPrice = (
    product: TParsedProductForContext,
    optionCodes: SwatchOptions['optionCodes'],
    optionSelections: SwatchOptions['optionSelections'],
    defaultCurrency?: string | null,
): TProductPrice => {
    const isConfigurable = isProductConfigurableAndHasOptions(product);

    if (isConfigurable && (!optionCodes || !optionSelections)) {
        return {
            currency: defaultCurrency || '',
            hasDiscount: false,
            maxPrice: 0,
            minPrice: 0,
            value: 0,
        };
    }

    const optionsSelected = [...optionSelections.values()].filter(Boolean).length;

    const defaultPrice = product?.price_range?.maximum_price?.final_price?.value || 0;

    const item =
        isConfigurable &&
        optionsSelected &&
        findMatchingVariant({
            optionCodes,
            optionSelections,
            variants: product?.variants,
        });

    const { price_range } = item?.product || product || {};
    const { maximum_price, minimum_price } = price_range || {};
    const { discount, final_price, regular_price } = minimum_price || {};
    const { currency = defaultCurrency || '', value } = regular_price || {};
    const maxRegularPrice = maximum_price?.regular_price.value;

    return {
        currency,
        hasDiscount: !!discount?.amount_off,
        maxPrice: maxRegularPrice ? Math.max(value, maxRegularPrice) : defaultPrice,
        minPrice: final_price?.value ? Math.min(value, final_price.value) : defaultPrice,
        value,
    };
};
