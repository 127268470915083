type SizeAttributeValues = {
    [attributeKey: string]: string[];
};

export type SizeAttribute = {
    [attributeKey: string]: {
        value_in_cm: number | null;
        value_in_inch: number | null;
    };
};

export const UNITS = {
    INCH: { dataKey: 'value_in_inch', value: 'inches' },
    // eslint-disable-next-line sort-keys-fix/sort-keys-fix
    CM: { dataKey: 'value_in_cm', value: 'centimeters' },
};

export type UnitsKeys = keyof typeof UNITS;

export interface ISizeChartDataResponse {
    getSizeChartAttributes: (SizeAttribute & {
        __typename: 'SizeChartAttributes';
        sku: string;
    })[];
}

export interface IUseProductSizeGuide {
    derivedErrorMessage: string;
    howToMeasureSizeContent: string | null;
    isSizeChartLoading: boolean;
    isSizeGuideModalOpen: boolean;
    isSizeInstructionsLoading: boolean;
    setIsSizeGuideModalOpen(value: boolean): void;
    sizeChartData: ISizeChartDataResponse | undefined;
}

export interface IUseProductSizeGuideTableProps {
    sizeChartData: ISizeChartDataResponse | undefined;
}

export type SizeTableRow = {
    attributeCode: string;
} & SizeAttributeValues;

export interface IUseProductSizeGuideTable {
    selectedUnit: UnitsKeys;
    setSelectedUnit(value: UnitsKeys): void;
    tableDataRows: SizeTableRow[];
    tableHeaderRow: string[];
}
