import { ConfigurableVariant, MediaGalleryEntry, SwatchOptions, SwatchOptionsWithVariants } from '@/types/product';
import { findMatchingVariant } from './findMatchingVariant';
import { withContentOrNull } from '@/utils/Array';

/**
 * Return a new optionSelections Map, excluding the 'size' attribute
 */
const excludeSizeAttribute = ({ optionCodes, optionSelections }: SwatchOptions): SwatchOptions['optionSelections'] =>
    new Map([...optionSelections].filter(([attribute]) => optionCodes.get(attribute) !== 'size'));

/**
 * Find a matching variant and return the MediaGalleryEntries, if it exists.
 */
const findClosestVariantMediaGalleryEntries = (
    options: SwatchOptionsWithVariants,
    overrideOptions: Partial<SwatchOptions> = {},
): MediaGalleryEntry[] | null => {
    const variant = findMatchingVariant({ ...options, ...overrideOptions }) as ConfigurableVariant | undefined;

    return withContentOrNull(variant?.product.media_gallery_entries);
};

/**
 * List of the closest variant MediaGalleryEntries.
 * Skips the 'size' option if no matching variant was found
 */
export const findMediaGalleryEntriesWithFallback = (options: SwatchOptionsWithVariants): MediaGalleryEntry[] =>
    findClosestVariantMediaGalleryEntries(options) ||
    findClosestVariantMediaGalleryEntries(options, { optionSelections: excludeSizeAttribute(options) }) ||
    [];
