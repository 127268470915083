import { useMemo } from 'react';
import { usePathname } from 'next/navigation';
import { PAGE_TYPES, useViewProduct } from '@/integrations/google-tag-manager';
import { useProductPage as useEmarsysProductPage } from '@/integrations/emarsys-tracking/src/integrations';
import { useProductPage as useMonetateProductPage } from '@/integrations/monetate/src/integrations';
import { useAddToCart, useViewContent } from '@/integrations/facebook-conversion-api';
import type { IUseProductPageIntegrationsProps } from '../types';
import { useTranslations } from 'next-intl';
import { useUserContext } from '@/lib/context';
import { useBoldMetricsContext } from '@/integrations/bold-metrics/src/context/boldMetrics';
import { useBoldMetricsScriptProductPage } from '@/integrations/bold-metrics';
import { usePayPalMessageScript } from '@/integrations/paypal-message';
import { deriveCurrentColorValue } from '@/components/RootComponents/Product/utils/getColorMatchingVariant';

export const useProductPageIntegrations = ({
    breadcrumbsItems,
    colorValue,
    facebookProductInCart,
    product,
}: IUseProductPageIntegrationsProps): void => {
    const [{ isSignedIn }] = useUserContext();
    const pathname = usePathname();
    const t = useTranslations('global');
    const { boldMetricsScriptPath, isBoldMetricsEnabled } = useBoldMetricsContext();

    const breadcrumbs = useMemo(
        () => (breadcrumbsItems ? [t('home'), ...breadcrumbsItems.map(({ text }) => text)] : []),
        [breadcrumbsItems],
    );

    useMonetateProductPage({
        breadcrumbs,
        colorValue: deriveCurrentColorValue(product, colorValue?.toString()),
        productSku: product.sku,
    });
    useViewProduct({ isSignedIn, pageType: PAGE_TYPES.PRODUCT, pathname, product, title: product.title });
    useEmarsysProductPage({ product });

    const pixelViewedProduct = useMemo(
        () => ({
            // @ts-expect-error TODO refactor with breadcrumbs to reduce RSC payload
            categories: product?.categories,
            currency: product?.price?.regularPrice?.amount?.currency,
            name: product?.name,
            sku: product?.sku,
            value: product?.price?.regularPrice?.amount?.value,
        }),
        [
            // @ts-expect-error see above
            product?.categories,
            product?.name,
            product?.price?.regularPrice?.amount?.currency,
            product?.price?.regularPrice?.amount?.value,
            product?.sku,
        ],
    );

    useAddToCart(facebookProductInCart);
    useViewContent(pixelViewedProduct);

    useBoldMetricsScriptProductPage({ boldMetricsScriptPath, isBoldMetricsEnabled });
    usePayPalMessageScript();
};
