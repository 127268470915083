'use client';

import React, { ReactElement, useMemo, useEffect, useState } from 'react';
import loadable from '@loadable/component';
import { useOption } from './hooks/useOption';
import { Typography, Link } from '@vaimo-int/aem-pwa-common-components';
import getOptionType from './getOptionType';
import SwatchList from './swatchList';
import TileList from '@/components/ProductOptions/modules/TileList/tileList';
import ProductSizeGuide from '../ProductSizeGuide';
import { useTranslations } from 'next-intl';
import { IProductOptionValue } from '@/components/ProductOptions/types';
import BoldMetricsButton from '@/integrations/bold-metrics/src/components/boldMetricsButton';
import clsx from 'clsx';
import classes from './option.module.scss';

const TextTransition = loadable(() => import('react-text-transition'));

const SIZE_CODE = 'size';
const VALUE_NOT_SELECTED = 'None';
const OPTION_TYPE_SWATCH = 'swatch';
enum DirectionsEnum {
    DOWN = 'down',
    UP = 'up',
}

const getItemKey = ({ value_index }: { value_index: number }) => value_index;

const getListComponent = (attribute_code: any) => {
    const optionType = getOptionType({ attribute_code });

    return optionType === OPTION_TYPE_SWATCH ? SwatchList : TileList;
};

interface IOptionProps {
    attribute_code: string;
    attribute_id: string | number;
    hhProductType?: string | null;
    isAddToCartDisabled: boolean;
    isFitAnalyticsEnabled?: boolean;
    isInStock: boolean;
    isShakeAnimationActive: boolean;
    isSizeGuideEnabled: boolean;
    label: string;
    onSelectionChange: (optionId: string, selection: number) => void;
    productName?: string;
    selectedColorSku?: string;
    selectedIndex: number;
    selectedValue: number | string;
    setSelectedSize?: (option: string) => void;
    sizeChart?: string | null;
    values: IProductOptionValue[];
}

const Option = ({
    attribute_code,
    attribute_id,
    hhProductType,
    isAddToCartDisabled,
    isInStock,
    isShakeAnimationActive,
    isSizeGuideEnabled,
    label,
    onSelectionChange,
    productName,
    selectedColorSku,
    selectedIndex,
    selectedValue,
    setSelectedSize,
    sizeChart,
    values,
}: IOptionProps): ReactElement => {
    const tProductPage = useTranslations('productPage');
    const tProductDetail = useTranslations('productDetail');
    const tProductSizeGuide = useTranslations('productSizeGuide');

    const { handleSelectionChange, initialSelection, selectedValueDescription } = useOption({
        attribute_id,
        label,
        onSelectionChange,
        selectedValue,
        values,
    });

    const [number, setNumber] = useState(selectedIndex);
    const [oldNumber, setOldNumber] = useState(selectedIndex);

    useEffect(() => {
        setNumber((int) => {
            setOldNumber(int);

            return selectedIndex;
        });

        if (attribute_code === SIZE_CODE && setSelectedSize) setSelectedSize(selectedValueDescription);
    }, [onSelectionChange]);

    const hasSelectedValue = selectedValueDescription !== VALUE_NOT_SELECTED;

    const isErrorNecessary = attribute_code === SIZE_CODE && isAddToCartDisabled && !hasSelectedValue;

    const ValueList = useMemo(() => getListComponent(attribute_code), [attribute_code]);

    const titleOption = useMemo(() => {
        const optionLabelClassName = clsx({
            [classes.optionOutOfStock]: attribute_code === SIZE_CODE && !isInStock,
            [classes.optionTitleLabel]: hasSelectedValue,
            [classes.optionTitleLabelError]: !hasSelectedValue && attribute_code === SIZE_CODE,
            [classes.optionTitleLabelShake]:
                attribute_code === SIZE_CODE && isAddToCartDisabled && isShakeAnimationActive,
        });

        const alertMessage = tProductPage('selectedMessage', { attribute_code });

        const message = () => {
            if (selectedValue && attribute_code === SIZE_CODE && !isInStock)
                return `${selectedValueDescription} - ${tProductDetail('outOfStock')}`;

            if (hasSelectedValue && !isErrorNecessary) return selectedValueDescription;

            if (attribute_code === SIZE_CODE) return tProductPage('selectSizeMessage');

            return alertMessage;
        };

        return (
            <>
                {`${label}: `}
                {attribute_code === SIZE_CODE && !hasSelectedValue ? null : (
                    <Typography.Body style={'basic'} wrapperElement={'div'}>
                        <TextTransition
                            className={optionLabelClassName}
                            springConfig={{ tension: 270 }}
                            inline
                            direction={number > oldNumber ? DirectionsEnum.UP : DirectionsEnum.DOWN}
                            delay={100}
                        >
                            {message()}
                        </TextTransition>
                    </Typography.Body>
                )}
                {attribute_code === SIZE_CODE && (
                    <div className={classes.sizeGuideLink}>
                        <BoldMetricsButton
                            hhProductType={hhProductType}
                            productName={productName}
                            sizeChart={sizeChart}
                        />

                        <div data-fit-finder={'size-guide'} className={classes.sizeGuideButtonWrapper}>
                            <Link
                                to={'/size-chart'}
                                hasHover={true}
                                hasUnderline={true}
                                text={tProductSizeGuide('sizeChart')}
                                variant={'default'}
                            />
                        </div>

                        {isSizeGuideEnabled && (
                            <ProductSizeGuide
                                availableSizesOptions={values}
                                selectedColorSku={selectedColorSku || ''}
                            />
                        )}
                    </div>
                )}
            </>
        );
    }, [
        isErrorNecessary,
        number,
        oldNumber,
        attribute_code,
        selectedValueDescription,
        isShakeAnimationActive,
        isInStock,
        selectedColorSku,
    ]);

    return (
        <div className={classes.option}>
            <Typography.Heading style={'etc'} className={classes.optionTitle}>
                {titleOption}
            </Typography.Heading>
            <ValueList
                getItemKey={getItemKey}
                selectedValue={initialSelection as IProductOptionValue}
                items={values}
                onSelectionChange={handleSelectionChange}
            />
        </div>
    );
};

export default Option;
