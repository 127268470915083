import React, { ReactElement } from 'react';

interface IBoldMetricsButtonProps {
    hhProductType?: string | null;
    productName?: string;
    sizeChart?: string | null;
}

const BoldMetricsButton = React.memo(
    ({ hhProductType, productName, sizeChart }: IBoldMetricsButtonProps): ReactElement | null => {
        if (!sizeChart || !hhProductType) {
            return null;
        }

        return <div id="boldmetrics-ssc-default" data-garment={productName} />;
    },
);

BoldMetricsButton.displayName = 'BoldMetricsButton';

export default BoldMetricsButton;
