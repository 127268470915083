import { useTranslations } from 'next-intl';

interface useCurrentStatusProps {
    status: string;
}

export const ADD_TO_CART_ACTION_STATUS = {
    default: 'DEFAULT',
    error: 'ERROR',
    loading: 'LOADING',
    more: 'MORE',
    ongoing: 'ONGOING',
    selectSize: 'SELECT_SIZE',
    success: 'SUCCESS',
};

export const useCurrentStatus = ({ status }: useCurrentStatusProps): string | undefined => {
    const t = useTranslations('addToCartButton');

    const MESSAGES = new Map([
        [ADD_TO_CART_ACTION_STATUS.error, t('error')],
        [ADD_TO_CART_ACTION_STATUS.more, t('addMore')],
        [ADD_TO_CART_ACTION_STATUS.default, t('default')],
        [ADD_TO_CART_ACTION_STATUS.ongoing, t('ongoing')],
        [ADD_TO_CART_ACTION_STATUS.success, t('success')],
        [ADD_TO_CART_ACTION_STATUS.selectSize, t('selectSize')],
        [ADD_TO_CART_ACTION_STATUS.loading, ''],
    ]);

    return MESSAGES.get(status);
};
