import { useCallback, useEffect, useState } from 'react';
import { useAppContext, useCartContext, useUserContext } from '@/lib/context';

import { useAddProductToCart } from '@/components/ProductAddToCart/hooks/useAddProductToCart';
import { useProductSwatches } from '@/components/ProductSwatches/hooks/useProductSwatches';
import { useProductPageIntegrations } from '../modules/ProductPageIntegrations/client';
import { useYotpo } from '@/integrations/yotpo';
import { addConfigurableProductToCartMutation } from '@/components/RootComponents/Product/api/productFullDetail.gql';

import type { IUseProduct, IUseProductProps } from '../types';
import { getPrices } from '@/components/RootComponents/Product/utils/getPrices';
import { ADD_TO_CART_ACTION_STATUS } from '@/talons/CurrentStatus/useCurrentStatus';
import { VALUE_NOT_SELECTED } from '@/components/RootComponents/Product/utils/const';

const useProduct = ({
    breadcrumbsItems,
    colorValue,
    currencyCode,
    freeShippingEligibleThreshold,
    isProductTypeConfigurable,
    isSupportedProductType,
    product,
    sizeValue,
}: IUseProductProps): IUseProduct => {
    const [{ cartId }] = useCartContext();
    const [{ isSignedIn }] = useUserContext();
    const [
        { storeConfig },
        {
            actions: { setAddToCartActionStatus },
        },
    ] = useAppContext();

    const [selectedSize, setSelectedSize] = useState<string>(VALUE_NOT_SELECTED);
    const [isShakeAnimationActive, setIsShakeAnimationActive] = useState<boolean>(false);
    const [shouldShakeButton, setShouldShakeButton] = useState<boolean>(false);

    const noSizeSelected = selectedSize === VALUE_NOT_SELECTED;

    const handleShakeAnimation = useCallback(() => {
        setIsShakeAnimationActive(true);

        if (!noSizeSelected) {
            setShouldShakeButton(false);
        } else {
            setAddToCartActionStatus(ADD_TO_CART_ACTION_STATUS.selectSize);
            setShouldShakeButton(true);
        }

        const timer = setTimeout(() => {
            setIsShakeAnimationActive(false);
            setShouldShakeButton(false);
        }, 500);

        return () => clearTimeout(timer);
    }, [noSizeSelected, setAddToCartActionStatus]);

    useEffect(() => {
        !noSizeSelected && setAddToCartActionStatus(ADD_TO_CART_ACTION_STATUS.default);
    }, [noSizeSelected, setAddToCartActionStatus]);

    useEffect(() => {
        return () => sessionStorage.setItem('fauxVI', 'negative');
    }, []);

    const {
        currentOptions,
        handleSelectionChange,
        isMissingOptions,
        isOutOfStock,
        mediaGalleryEntries,
        optionCodes,
        optionSelections,
        productOptions,
        productPrice,
        wishlistButtonProps,
    } = useProductSwatches({
        colorValue,
        currencyCode,
        fitAnalyticsObject: {
            addToCartMutation: addConfigurableProductToCartMutation,
            cartId,
            isEnabled: storeConfig.isFitAnalyticsEnabled,
            isSignedIn,
            scriptSrc: storeConfig.fitAnalyticsPdpScript,
            storeCode: storeConfig.storeCode,
        },
        handleShakeAnimation,
        isProductTypeConfigurable,
        product,
        setIsShakeAnimationActive,
        sizeValue,
    });

    const { errors, facebookProductInCart, handleAddToCart, isAddConfigurableLoading, isAddSimpleLoading } =
        useAddProductToCart({
            currentOptions,
            handleShakeAnimation,
            isMissingOptions,
            isSupportedProductType,
            mediaGalleryEntries,
            optionCodes,
            optionSelections,
            product,
            productPrice,
        });

    const getLowestPriceData = getPrices(currencyCode, optionCodes, optionSelections, product);

    useProductPageIntegrations({
        breadcrumbsItems,
        colorValue,
        facebookProductInCart,
        product,
    });

    const { fitSizeInfo, getYotpoRating, reviews } = useYotpo({
        currentProductId: product.id,
        productIds: product ? [product.id] : [],
    });

    return {
        currentOptions,
        description: product.description,
        errors,
        fitSizeInfo,
        handleAddToCart,
        handleSelectionChange,
        handleShakeAnimation,
        hasDiscount: getLowestPriceData.hasDiscount,
        hhProductType: product?.hh_product_type,
        id: product.id,
        isAddToCartDisabled:
            !isSupportedProductType ||
            isMissingOptions ||
            isOutOfStock ||
            isAddConfigurableLoading ||
            isAddSimpleLoading,
        isFreeShipping: freeShippingEligibleThreshold && productPrice?.minPrice > +freeShippingEligibleThreshold,
        isInStock: !isOutOfStock,
        isMissingOptions,
        isShakeAnimationActive,
        lowestPriceData: getLowestPriceData.lowestPriceData,
        mediaGalleryEntries,
        name: product.name,
        noSizeSelected,
        optionSelections,
        productOptions,
        productPrice,
        rating: getYotpoRating(product.id),
        reviews,
        selectedColorSku: `${product?.sku}_${colorValue}`,
        selectedSize,
        setIsShakeAnimationActive,
        setSelectedSize,
        shouldShakeButton,
        sizeChart: product?.sizechart,
        variants: product.variants,
        wishlistButtonProps,
    };
};

export default useProduct;
