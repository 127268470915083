import type { ConfigurableProductOptionsValues } from '@/types/product';
import { TParsedProductForContext } from '@/components/RootComponents/Product/types';
import { COLOR_CODE, SIZE_CODE } from '@/components/RootComponents/Product/utils/const';

export const getMatchingVariant = (
    product: TParsedProductForContext | undefined,
    code?: string | null,
    isSize: boolean = false,
): ConfigurableProductOptionsValues | undefined => {
    if (!product) return;

    const variants = product.configurable_options?.find(({ attribute_code }) =>
        isSize ? attribute_code === SIZE_CODE : attribute_code === COLOR_CODE,
    )?.values;

    let matchingVariant = code && variants?.find(({ value_index }) => value_index.toString() === code);

    if (!matchingVariant) {
        matchingVariant = variants?.find((variant) => variant);
    }

    return matchingVariant;
};

// Needed for Emarsys to show selected product's SKU as '30253-001'
// We have configurable SKU available, but not simple,
// here we retrieve 3-digit color code from the URL param color id or first available one
export const deriveCurrentColorValue = (
    product: TParsedProductForContext | undefined,
    colorCode?: string | null,
): string | undefined => {
    if (!product) return;

    const matchingVariant = getMatchingVariant(product, colorCode);

    return matchingVariant?.default_label?.slice(0, 3);
};
