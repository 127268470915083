import { findMatchingVariant } from './findMatchingVariant';
import { productHasDiscount } from './productHasDiscount';
import { getMediaGalleryEntries } from './getMediaGalleryEntries';
import type { ConfigurableProductOptions, SwatchOptions } from '@/types/product';
import { TParsedProductForContext } from '@/components/RootComponents/Product/types';
import { COLOR_CODE } from '@/components/RootComponents/Product/utils/const';
import { PRODUCT_IMAGE_URL_PREFIX } from '@/components/OptimizedImage/utils/prefixes';

const OUT_OF_STOCK_CODE = 'OUT_OF_STOCK';

export const modifiedConfigOptions = (
    product: TParsedProductForContext,
    selectedValues: SwatchOptions['optionSelections'],
    optionCodes: SwatchOptions['optionCodes'],
): ConfigurableProductOptions[] => {
    if (!product || !selectedValues || !optionCodes) {
        return [];
    }

    const { configurable_options = [], variants } = product;

    return configurable_options.map((option) => {
        const { attribute_code, attribute_id, values } = option;

        const newValues = values.map((item) => {
            const matchingOptions = new Map(selectedValues);
            matchingOptions.set(attribute_id, item.value_index);

            const matchingItem = findMatchingVariant({
                optionCodes,
                optionSelections: matchingOptions,
                variants,
            });

            if (attribute_code === COLOR_CODE) {
                const colorOption = new Map();
                colorOption.set(attribute_id, item.value_index);

                const matchingColorItem = findMatchingVariant({
                    optionCodes,
                    optionSelections: colorOption,
                    variants,
                });
                const mediaFiles = getMediaGalleryEntries(product, optionCodes, colorOption);
                const file = matchingColorItem?.product?.thumbnail?.url || mediaFiles?.[0]?.file || '';
                const hasDiscount = productHasDiscount(product.variants, item.value_index);

                return {
                    ...item,
                    file: file.includes(PRODUCT_IMAGE_URL_PREFIX) ? file : `/${PRODUCT_IMAGE_URL_PREFIX}${file}`,
                    hasDiscount,
                    isHidden: !matchingColorItem,
                };
            }

            return {
                ...item,
                isDisabled: !matchingItem || matchingItem.product.stock_status === OUT_OF_STOCK_CODE,
            };
        });

        return {
            ...option,
            values: newValues,
        };
    });
};
